<template>
	<div class="index">
		<div class="_pc-pad-x-280 _flex-row-center-mid _pc-pad-bottom-70 mb-pad-x-15">
			<div class="search-box mb-border-radius-10 mb-h-60 mb-pad-x-15">
				<div class="search-box-left">
					<img class="_pc-w-30 _pc-mar-right-25 mb-mar-right-15 mb-w-18" src="~@/assets/image/hangda/sousuo.png" alt="">
					<input type="text" class="_pc-font-24 mb-flex-1 mb-font-15" v-model.trim="expresssn" :placeholder="$t('请输入查询单号...')" @keyup.enter="search">
				</div>
				<div class="search-box-right _pc-pad-x-30 display-none" @click="search()">{{ $t('search') }}</div>
				<div class="mb-search-btn _pc-display-none mb-font-15" @click="search()">{{ $t('search') }}</div>
			</div>
		</div>
		<div style="display: flex;flex-direction: column;">
			<order-steps class="_pc-pad-bottom-70 _pc-pad-x-280 mb-order-2 mb-pad-x-15"></order-steps>
			<jy-service class="_pc-pad-bottom-70 _pc-pad-x-280 mb-order-1 mb-pad-x-15 mb-mar-top-30"></jy-service>
			<reckon class="mb-order-3" v-if="agent_info.is_default>0"></reckon>
		</div>
		<div class="index-label-box _flex-row-bet _w-max _pc-pad-x-280" style="background-color: #f5f5f5;">
			<div class="center-label _flex-row-mid" style="margin-top: 30px;">
				<div class="li _flex-row-mid" style="display: flex;justify-content: center;align-items: center;text-align: center;">
					<img class="_pc-mar-right-20 display-none" style="width: 64px;" src="~@/assets/image/hangda/labelItem1@2x.png" alt="">
					<img class="_pc-mar-right-20 _pc-display-none" style="width: 24px;" src="~@/assets/image/hangda/labelItem1@2x.png" alt="">
					<div class="_pc-font-20 mb-font-15" style="line-height: 1.7;color: #999;word-break: break-all;">
						{{ $t('高效准时') }}
					</div>
				</div>
				<div class="li _flex-row-mid" style="display: flex;justify-content: center;align-items: center;text-align: center;">
					<img class="_pc-mar-right-20 display-none" style="width: 64px;" src="~@/assets/image/hangda/labelItem2@2x.png" alt="">
					<img class="_pc-mar-right-20 _pc-display-none" style="width: 24px;" src="~@/assets/image/hangda/labelItem2@2x.png" alt="">
					<div class="_pc-font-20 mb-font-15" style="line-height: 1.7;color: #999;word-break: break-all;">
						{{ $t('细心服务') }}
					</div>
				</div>
				<div class="li _flex-row-mid" style="display: flex;justify-content: center;align-items: center;text-align: center;">
					<img class="_pc-mar-right-20 display-none" style="width: 64px;" src="~@/assets/image/hangda/labelItem3@2x.png" alt="">
					<img class="_pc-mar-right-20 _pc-display-none" style="width: 24px;" src="~@/assets/image/hangda/labelItem3@2x.png" alt="">
					<div class="_pc-font-20 mb-font-15" style="line-height: 1.7;color: #999;word-break: break-all;">
						{{ $t('便携集运') }}
					</div>
				</div>
				<div class="li _flex-row-mid" style="display: flex;justify-content: center;align-items: center;text-align: center;">
					<img class="_pc-mar-right-20 display-none" style="width: 64px;" src="~@/assets/image/hangda/labelItem4@2x.png" alt="">
					<img class="_pc-mar-right-20 _pc-display-none" style="width: 24px;" src="~@/assets/image/hangda/labelItem4@2x.png" alt="">
					<div class="_pc-font-20 mb-font-15" style="line-height: 1.7;color: #999;word-break: break-all;">
						{{ $t('值得信赖') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import orderSteps from './components/orderSteps';
	import jyService from './components/jyService';
	import reckon from './components/reckon';
	import { bannerList } from '@/api/jiyun.js';
	import { setCookies } from "@/libs/util";
	export default {
		components: {
			orderSteps,
			jyService,
			reckon
		},
		data() {
			return {
				expresssn: '',
				IsLogin: false,
				agent_info: {},
			}
		},
		metaInfo() {
			return {
				title: this.$t('page_title.index'), // set a title
			};
		},
		created(){
			setCookies('consent',false)
			this.bannerList();
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 搜索
			search() {
				this.$router.push({
					path: '/search',
					query:{
						expresssn:this.expresssn
					}
				})
			},
		}
	}
</script>


<style scoped>
	input:not(:first-child) {
		flex: 1;
		height: 100%;
		outline: none;
	}

	input:not(:first-child)::placeholder {
		color: #999 !important;
		/* font-size: 24px; */
	}

	/* input::first-child::placeholder {
		color: #999 !important;
		font-size: 24px;
	} */
	.index {
		position: relative;
		padding-bottom: 125px;
	}

	.search-box {
		width: 100%;
		height: 110px;
		border-radius: 28px;
		background-color: #ffffff;
		box-shadow: 0px 8px 40px 0px rgba(69, 69, 69, 0.08);
		margin-top: -55px;
		padding: 21px 21px 21px 31px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.search-box .search-box-left {
		display: flex;
		align-items: center;
		height: 100%;
		flex: 1;
	}

	.search-box .search-box-right {
		min-width: 147px;
		height: 68px;
		background: #4284DC;
		border-radius: 12px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		cursor: pointer;
	}

	.index-label-box {
		height: 125px;
		/* background-color: #eee; */
		position: absolute;
		bottom: 0;

	}

	.index-label-box .first-label {
		width: 270px;
		height: 65px;
		border-right: 1px solid rgba(188, 188, 188, 0.5);
	}

	.index-label-box .last-label {
		width: 270px;
		height: 65px;
		border-left: 1px solid rgba(188, 188, 188, 0.5);
		padding-left: 60px;
	}

	.index-label-box .center-label {
		flex: 1;
		height: 65px;

	}

	.index-label-box .center-label .li {
		width: calc(100% / 4);
		height: 100%;
	}
	@media screen and (min-width: 768px){
		.index-label-box .center-label .li {
			border-left: 1px solid rgba(188, 188, 188, 0.5);
		}
		.index-label-box .center-label .li:first-child {
			border-left: none;
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.search-box{
			margin-top:-20px;
		}
		.mb-search-btn {
			padding: 0.5rem 0.9375rem;
			background: #4284DC;
			border-radius: 8px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		* {
			padding: 0;
			margin: 0;
		}
		*,
		*::before,
		*::after {
		  	box-sizing: border-box;
		}
		ul {
			padding: 0 !important;
		}
		ul>li{
			list-style: none;
		}
		.display-none {
			display: none !important;
		}
		/* pad */
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		/* mar */
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
	}
</style>