<template>
	<div class="">
		<div class="title _pc-font-34 _b _pc-pad-bottom-35 mb-font-25" style="color: #333333;">{{ $t('集运服务') }}</div>
		<div class="content _w-max mb-mar-y-15">
			<div class="li-box _flex-row-mid _pc-pad-left-40 _pc-pad-right-30 mb-pad-0 display-none" :class="{'li-box-select':index === select_id}" v-for="(item,index) in list" :key="index" @mouseenter="select_id = index" @mouseleave="select_id = ''" @click="RouterLink(item.link)">
				<img class="_pc-w-100 mb-w-70" :src="item.imgUrlselect" alt="" v-if="index === select_id">
				<img class="_pc-w-100 mb-w-70" :src="item.imgUrl" alt="" v-else>
				<div class="_pc-mar-left-45 li-box-right _flex-row-bet-mid _pos-rel mb-mar-0 mb-w-max">
					<div class="mb-w-max">
						<div class="title mb-text-center mb-font-20" :style="index === select_id?'color:#fff;':''">{{item.title}}</div>
						<div class="content _pc-mar-top-25 mb-font-15 mb-w-max mb-display-block mb-text-center mb-pad-x-30" :style="index === select_id?'color:#fff;':''">{{item.content}}</div>
					</div>
					<div class="iconfont2 icon-jiantou _pc-font-17 display-none" :style="index === select_id?'color:#fff;':''"></div>
				</div>
			</div>
			<div class="li-box _flex-row-mid _pc-pad-left-40 _pc-pad-right-30 mb-pad-0 _pc-display-none" :class="{'li-box-select':index === select_id}" v-for="(item,index) in mb_list" :key="index" @mouseenter="select_id = index" @mouseleave="select_id = ''" @click="RouterLink(item.link)">
				<img class="_pc-w-100 mb-w-70" :src="item.imgUrlselect" alt="" v-if="index === select_id">
				<img class="_pc-w-100 mb-w-70" :src="item.imgUrl" alt="" v-else>
				<div class="_pc-mar-left-45 li-box-right _flex-row-bet-mid _pos-rel mb-mar-0 mb-w-max">
					<div class="mb-w-max">
						<div class="title mb-text-center mb-font-20" :style="index === select_id?'color:#fff;':''">{{item.title}}</div>
						<div class="content _pc-mar-top-25 mb-font-15 mb-w-max mb-display-block mb-text-center mb-pad-x-30" :style="index === select_id?'color:#fff;':''">{{item.content}}</div>
					</div>
					<div class="iconfont2 icon-jiantou _pc-font-17 display-none" :style="index === select_id?'color:#fff;':''"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { bannerList } from '@/api/jiyun.js'
	export default {
		data() {
			return {
				list: [],
				mb_list: [],
				li_box_select:false,
				select_id:''
			}
		},
		created(){
			this.bannerList();
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
					console.log(this.agent_info)
					if(this.agent_info.is_main){
						this.list = [
							{
								title: this.$t('包裹预报'),
								content: this.$t('预报您的包裹信息'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem1.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem8.png'),
								link:'/report'
							},
							{
								title: this.$t('我的订单'),
								content: this.$t('查看我的集运订单'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem5.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/user'
							},
							{
								title: this.$t('帮助中心'),
								content: this.$t('您想知道的集运常见问题都在这'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem6.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem3.png'),
								link:'/question'
							},
							{
								title: this.$t('关于我们'),
								content: this.$t('关于我们的介绍'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem7.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem4.png'),
								link:'/about_us'
							},
							{
								title: this.$t('地址管理'),
								content: this.$t('管理我的收货地址'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem10.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/user'
							},
							{
								title: this.$t('申请打包'),
								content: this.$t('申请包裹合包寄出'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem9.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/list'
							}
						];
						this.mb_list = [
							{
								title: this.$t('包裹预报'),
								content: this.$t('预报您的包裹信息'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem1.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem8.png'),
								link:'/report'
							},
							{
								title: this.$t('我的订单'),
								content: this.$t('查看我的集运订单'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem5.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/orderList'
							},
							{
								title: this.$t('帮助中心'),
								content: this.$t('您想知道的集运常见问题都在这'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem6.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem3.png'),
								link:'/question'
							},
							{
								title: this.$t('关于我们'),
								content: this.$t('关于我们的介绍'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem7.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem4.png'),
								link:'/mb_about_us'
							},
							{
								title: this.$t('地址管理'),
								content: this.$t('管理我的收货地址'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem10.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/addressList'
							},
							{
								title: this.$t('申请打包'),
								content: this.$t('申请包裹合包寄出'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem9.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/mb_list'
							}
						];
					}else{
						this.list = [
							{
								title: this.$t('包裹预报'),
								content: this.$t('预报您的包裹信息'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem1.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem8.png'),
								link:'/report'
							},
							{
								title: this.$t('我的订单'),
								content: this.$t('查看我的集运订单'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem5.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/user'
							},
							{
								title: this.$t('帮助中心'),
								content: this.$t('您想知道的集运常见问题都在这'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem6.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem3.png'),
								link:'/question'
							},
							{
								title: this.$t('关于我们'),
								content: this.$t('关于我们的介绍'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem7.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem4.png'),
								link:'/about_us'
							}
						];
						this.mb_list = [
							{
								title: this.$t('包裹预报'),
								content: this.$t('预报您的包裹信息'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem1.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem8.png'),
								link:'/report'
							},
							{
								title: this.$t('我的订单'),
								content: this.$t('查看我的集运订单'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem5.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem2.png'),
								link:'/orderList'
							},
							{
								title: this.$t('帮助中心'),
								content: this.$t('您想知道的集运常见问题都在这'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem6.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem3.png'),
								link:'/question'
							},
							{
								title: this.$t('关于我们'),
								content: this.$t('关于我们的介绍'),
								imgUrl:require('@/assets/image/hangda/jyServiceItem7.png'),
								imgUrlselect:require('@/assets/image/hangda/jyServiceItem4.png'),
								link:'/mb_about_us'
							}
						];
					}
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			RouterLink(url) {
				this.$router.push({
					path:url
				})
			}
		}
	}
</script>

<style scoped>
	@media screen and (min-width: 768px){
		.li-box {
			width: calc((100% - 40px) / 2);
			height: 195px;
			border-radius: 18px;
			background-color: #f5f5f5;
			margin-bottom: 35px;
			cursor: pointer;
		}
		.li-box .li-box-right {
			flex: 1;
		}
		.li-box:last-child {
			margin-bottom: 0px;
		}
		.li-box:nth-last-child(2) {
			margin-bottom: 0px;
		}
		.li-box-select {
			background-color: #4284DC;
		}
		.title {
			color: #333333;
			font-size: 32px;
			font-weight: bold;
		}
		.content {
			color: #999999;
			font-size: 22px;
			/* _flex-row-bet _flex-wrap */
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 1.25rem;
		}
		.li-box {
			width: 100%;
			height: 100%;
			aspect-ratio: 1/1;
			border-radius: 8px;
			background-color: #f5f5f5;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.li-box-select {
			background-color: #4284DC;
		}
		.title {
			color: #333333;
			font-size: 32px;
			font-weight: bold;
		}
		.content {
			color: #999999;
			font-size: 22px;
		}
		.mb-search-btn {
			padding: 0.5rem 0.9375rem;
			background: #4284DC;
			border-radius: 8px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
	}
</style>