<template>
	<div class="_w-max">
		<div class="title _pc-font-34 _b _pc-pad-bottom-35 display-none" style="color: #333333;">{{ $t('下单步骤') }}</div>
		<div class="_w-max _flex-row-bet display-none">
			<div class="left-box box">
				<div class="li _pc-mar-bottom-25 _pc-pad-y-30 _pc-pad-x-25">
					<div class="_pos-abs _white _pc-font-18" style="left: 0;top: 30px;padding: 7px 10px;border-radius: 0px 100px 100px 0px;background-color: #4284DC;">01</div>
					<div class="_pc-mar-left-45 _pc-font-24 _pc-mar-bottom-15" style="color: #333333;">{{ $t('登录 注册') }}</div>
					<div class="_pc-font-18" style="color: #999999;line-height: 1.7;">{{ $t('点击左上角的“登录”,如果您还没有账号，请在登录页面点击“注册”。') }}</div>
				</div>
				<div class="li _pc-mar-bottom-25 _pc-pad-y-30 _pc-pad-x-25">
					<div class="_pos-abs _white _pc-font-18" style="left: 0;top: 30px;padding: 7px 10px;border-radius: 0px 100px 100px 0px;background-color: #4284DC;">02</div>
					<div class="_pc-mar-left-45 _pc-font-24 _pc-mar-bottom-15" style="color: #333333;">{{ $t('包裹预报') }}</div>
					<div class="_pc-font-18" style="color: #999999;line-height: 1.7;">{{ $t('点击包裹预报按钮，输入您要发货的快递单号，选择包裹中的商品类别，点击提交。') }}</div>
				</div>
				<div class="li _pc-pad-y-30 _pc-pad-x-25">
					<div class="_pos-abs _white _pc-font-18" style="left: 0;top: 30px;padding: 7px 10px;border-radius: 0px 100px 100px 0px;background-color: #4284DC;">03</div>
					<div class="_pc-mar-left-45 _pc-font-24 _pc-mar-bottom-15" style="color: #333333;">{{ $t('物流查询') }}</div>
					<div class="_pc-font-18" style="color: #999999;line-height: 1.7;">{{ $t('点击“我的订单”，点击订单详情，复制物流单号，返回首页，将订单号粘贴到查询栏，即可查询物流进度。') }}</div>
				</div>
			</div>
			<div class="right-box box">
				<div class="li _pc-pad-y-25 _pc-pad-left-30 _pc-font-30 _b">{{ $t('warehouse_address') }}</div>
				<div class="li _pc-pad-x-30">
					<li class="_pc-pad-y-25" style="list-style:none;" @click="onCopy(agent_info.is_show_no ? info.province+info.city+info.area+info.address+ ' ' +agent_info.warehouse_address+'（'+userInfo.province_name_alias+userInfo.copy+'_'+userInfo.user_code+'）' : info.province+info.city+info.area+info.address+ ' ' +agent_info.warehouse_address+'（'+userInfo.copy+'_'+userInfo.user_code+'）')">
						<div class="_w-max _flex-row-bet-mid _pc-mar-bottom-10 _pc-font-20">
							<div style="color: #333333;">{{ $t('地址') }}</div>
							<div class="_flex-row-mid" style="color: #4284DC;cursor: pointer;">
								<img class="_pc-mar-right-5" style="width: 13px;height: 13px;" src="~@/assets/image/hangda/copy.png" alt="">
								<span>{{ $t('复制') }}</span>
							</div>
						</div>
						<div class="_pc-font-18" style="color: #999;line-height: 1.7;" v-if="Islogin">{{ info.province+info.city+info.area+info.address }}{{ agent_info.warehouse_address||'' }}
							<span v-if="agent_info.is_show_no">{{ userInfo.province_name_alias}}_</span>
							{{userInfo.copy}}{{'_' + userInfo.user_code }}
						</div>
						<div class="_pc-font-18" style="color: #999;line-height: 1.7;" v-else>{{ 'xxxxxx' }}</div>
					</li>
					<li class="_pc-pad-y-25" style="list-style:none;" @click="onCopy(info.tel)">
						<div class="_w-max _flex-row-bet-mid _pc-mar-bottom-10 _pc-font-20">
							<div style="color: #333333;">{{ $t('联系电话') }}</div>
							<div class="_flex-row-mid" style="color: #4284DC;cursor: pointer;">
								<img class="_pc-mar-right-5" style="width: 13px;height: 13px;" src="~@/assets/image/hangda/copy.png" alt="">
								<span>{{ $t('复制') }}</span>
							</div>
						</div>
						<div class="_pc-font-30" style="color: #4284DC;line-height: 1.7;" v-if="Islogin">{{ info.tel }}</div>
						<div class="_pc-font-30" style="color: #4284DC;line-height: 1.7;" v-else>{{ 'xxxxxx' }}</div>
					</li>
					<li class="_pc-pad-y-25" style="list-style:none;" @click="onCopy(agent_info.is_show_no ? userInfo.province_name_alias + userInfo.copy + '_' + userInfo.user_code : userInfo.copy + '_' + userInfo.user_code )">
						<div class="_w-max _flex-row-bet-mid _pc-mar-bottom-10 _pc-font-20">
							<div style="color: #333333;">{{ $t('recipient') }}</div>
							<div class="_flex-row-mid" style="color: #4284DC;cursor: pointer;">
								<img class="_pc-mar-right-5" style="width: 13px;height: 13px;" src="~@/assets/image/hangda/copy.png" alt="">
								<span>{{ $t('复制') }}</span>
							</div>
						</div>
						<div class="_pc-font-18" style="color: #999;line-height: 1.7;" v-if="Islogin"><span v-if="agent_info.is_show_no">{{ userInfo.province_name_alias}}_</span>{{userInfo.copy}}{{'_'+userInfo.user_code }}</div>
						<div class="_pc-font-18" style="color: #999;line-height: 1.7;" v-else>{{ 'xxxxxx' }}</div>
					</li>
				</div>
				<div class="li _pc-pad-y-25 _flex-row-center-mid _pc-font-24" style="cursor: pointer;" @click="Allcopy()">
					<img style="width: 20px;height: 20px;" src="~@/assets/image/hangda/copy.png" alt="">
					<div class="_pc-mar-left-10">{{ $t('一键复制') }}</div>
				</div>
			</div>
		</div>
		
		<div class="mb-order-help-container _pc-display-none">
			<div class="title">
				下单流程
			</div>
			<div class="content-box">
				<div class="li __1">
					<div class="header" data-li-num="01">
						<span>{{ $t('登录') }}</span>
						<span>{{ $t('注册') }}</span>
					</div>
					<article class="article">
						{{ $t('点击左上角的“登录”,如果您还没有账号，请在登录页面点击“注册”。') }}
					</article>
				</div>
				<div class="li __2">
					<div class="header" data-li-num="02">
						<span>{{ $t('包裹预报') }}</span>
					</div>
					<article class="article">
						{{ $t('点击包裹预报按钮，输入您要发货的快递单号，选择包裹中的商品类别，点击提交。') }}
					</article>
				</div>
				<div class="li __3">
					<div class="header" data-li-num="03">
						<span>{{ $t('物流查询') }}</span>
					</div>
					<article class="article">
						{{ $t('点击“我的订单”，点击订单详情，复制物流单号，返回首页，将订单号粘贴到查询栏，即可查询物流进度。') }}
					</article>
				</div>
			</div>
		</div>
		<div class="mb-address-container _pc-display-none" style="padding-bottom: 1.875rem;">
			<div class="title">
				仓库地址
			</div>
			<div class="content-box">
				<div class="li">
					<div class="name">{{ $t('地址') }}</div>
					<div style="color: #999999;">
						{{ info.province+info.city+info.area+info.address }}{{ agent_info.warehouse_address||'' }}{{ userInfo.province_name_alias||''}}{{userInfo.copy||''}}_{{userInfo.user_code||'' }}
					</div>
					<img @click="onCopy(info.province+info.city+info.area+info.address+ ' ' +agent_info.warehouse_address+'（'+userInfo.province_name_alias+userInfo.copy+'_'+userInfo.user_code+'）')" class="_pc-mar-right-5" src="~@/assets/image/hangda/copy.png" alt="">
				</div>
				<div class="li">
					<div class="name">{{ $t('联系电话') }}</div>
					<div style="color: #4284DC;font-weight: bold;">
						{{ info.tel }}
					</div>
					<img @click="onCopy(info.tel)" class="_pc-mar-right-5" src="~@/assets/image/hangda/copy.png" alt="">
				</div>
				<div class="li">
					<div class="name">{{ $t('recipient') }}</div>
					<div style="color: #999;">
						{{ userInfo.province_name_alias||''}}{{userInfo.copy||''}}_{{userInfo.user_code||'' }}
					</div>
					<img @click="onCopy( userInfo.province_name_alias + userInfo.copy + '_' + userInfo.user_code)" class="_pc-mar-right-5" src="~@/assets/image/hangda/copy.png" alt="">
				</div>
				<div class="li mb-grid-column-max-span" style="display: flex;align-items: center;justify-content: center;"  @click="Allcopy()">
					<img class="_pc-mar-right-5" src="~@/assets/image/hangda/copy.png" alt="">
					<div style="color: #4284DC;">{{ $t('一键复制') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCookies } from "@/libs/util.js"
	import { storehouseInfo, getUserInfo, bannerList } from '@/api/jiyun';
	export default {
		data() {
			return {
				info:{},
				userInfo:{},
				Islogin: false,
				agent_info: {},
			}
		},
		mounted(){
			this.storehouseInfo();
		},
		created(){
			let token = getCookies('web-token');
			if(token){
				this.getUserInfo();
			}else{
				this.Islogin = false;
			}
			this.bannerList();
		},
		methods: {
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 获取用户信息
			getUserInfo(){
				getUserInfo().then((res)=>{
					this.userInfo = res.data;
					this.Islogin = true;
				}).catch((err)=>{
					this.Islogin = false;
				})
			},
			// 仓库地址
			storehouseInfo(){
				let param = {
					domain_url: window.location.origin
				}
				storehouseInfo(param).then((res)=>{
					this.info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			onCopy(text) {
				let that = this;
				if(this.Islogin){
					this.$copyText(text).then(function(e){
						that.$Message.success(that.$t('复制成功'))
					}),function(e){
						that.$Message.error(that.$t('复制失败'))
					}
				}else{
					this.$Message.info(this.$t('请登录'));
				}
			},
			copyError(e) {
				this.$Message.error(this.$t('复制失败'))
			},
			Allcopy:function () {
				let that = this;
				if(!that.Islogin) return that.$Message.info(that.$t('请登录'));
				let name = that.userInfo.province_name_alias;
				let two_name = that.agent_info.is_show_no ? name.split('').join(' ') : '';
				let temp = that.userInfo.copy + '_';
				two_name = two_name+temp;
				let code = that.userInfo.user_code;
				let tel = that.info.tel;
				let address = `${that.info.province}${that.info.city}${that.info.area}${that.info.address}${that.agent_info.warehouse_address||''}`;
				let text = `收件人：${name}\n联系电话：${tel}\n收货地址：${address}(${two_name}${code})`
				this.$copyText(text).then(function (e) {
					that.$Message.success(that.$t('复制成功'))
				}, function (e) {
					that.$Message.error(that.$t('复制失败'))
				})
			}
		}
	}
</script>

<style scoped>
	@import url('~@/assets/css/mbapp.css');
	.mb-order-help-container {
		width: 100%;
		margin-top: 1.25rem;
	}
	.mb-order-help-container .title {
		font-weight: bold;
		font-size: 1.5625rem;
	}
	.mb-address-container {
		width: 100%;
		margin-top: 1.25rem;
	}
	.mb-address-container .title {
		font-weight: bold;
		font-size: 1.5625rem;
	}
	.mb-order-help-container .content-box {
		width: 100%;
	}
	.mb-order-help-container .content-box .li{
		width: 100%;
		border-radius: 0.5rem;
		border: 1px solid #e4e4e4;
		background-color: #f5f5f5;
		padding: 0.9375rem 0;
		margin-top: 0.625rem;
		position: relative;
	}
	.mb-order-help-container .content-box .li .header {
		width: 100%;
		font-size: 1.125rem;
		position: relative;
		display: flex;
		align-items: center;
		column-gap: 0.625rem;
		font-weight: bold;
		color: #333333;
		padding-left: 2.5rem;
	}
	.mb-order-help-container .content-box .li .header::before {
		content: attr(data-li-num);
		position: absolute;
		left: 0;
		aspect-ratio: 1.3/1;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0 10px 10px 0;
		height: 90%;
		top: 1%;
		background-color: #4284DC;
		color: #fff;
		font-size: 0.875rem;
	}
	.mb-order-help-container .content-box .li .article {
		padding-left: 0.9375rem;
		color: #999;
		font-size: 0.9375rem;
		line-height: 1.8;
	}
	.mb-address-container .content-box {
		width: 100%;
		margin-top: 0.625rem;
		border-radius: 0.5rem;
		border: 1px solid #e4e4e4;
		background-color: #f5f5f5;
		position: relative;
		padding: 0rem 0.625rem;
	}
	.mb-address-container .content-box .li {
		padding: 0.9375rem 0rem;
		width: 100%;
		display: grid;
		grid-template-columns: 4rem 1fr auto;
		grid-column-gap: 0.875rem;
		font-size: 1rem;
		border-bottom:1px solid #e4e4e4;
	}
	.mb-address-container .content-box .li:last-child {
		border-bottom: none;
	}
	.mb-address-container .content-box .li .name {
		width: 100%;
		word-wrap: break-word;
		text-align: justify;
		text-align-last: justify;
	}
	.mb-address-container .content-box .li img {
		width: 1.25rem;
	}
	@media screen and (min-width: 768px){
		.box {
			width: calc((100% - 30px) / 2);
		}
		.left-box {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.left-box .li {
			border-radius: 14px;
			border: 1px solid #e4e4e4;
			background-color: #f5f5f5;
			position: relative;
		}
		
		.right-box {
			border-radius: 14px;
			background-color: #f5f5f5;
			border: 1px solid #e4e4e4;
			position: relative;
		}
		
		.right-box .li {
			border-bottom: 1px solid rgba(204, 204, 204, 0.5);
		}
		
		.right-box .li:last-child {
			border-bottom: none;
		}
		
		.right-box .li:last-child {
			color: #4284DC;
		}
		
		.right-box .li li {
			border-bottom: 1px solid rgba(204, 204, 204, 0.5);
		}
		
		.right-box .li li:last-child {
			border-bottom: none;
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.box {
			width: calc((100% - 30px) / 2);
		}
		.left-box {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.left-box .li {
			border-radius: 14px;
			border: 1px solid #e4e4e4;
			background-color: #f5f5f5;
			position: relative;
		}
		
		.right-box {
			border-radius: 14px;
			background-color: #f5f5f5;
			border: 1px solid #e4e4e4;
			position: relative;
		}
		
		.right-box .li {
			border-bottom: 1px solid rgba(204, 204, 204, 0.5);
		}
		
		.right-box .li:last-child {
			border-bottom: none;
		}
		
		.right-box .li:last-child {
			color: #4284DC;
		}
		
		.right-box .li li {
			border-bottom: 1px solid rgba(204, 204, 204, 0.5);
		}
		
		.right-box .li li:last-child {
			border-bottom: none;
		}
		.content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 1.25rem;
		}
		.li-box {
			width: 100%;
			height: 100%;
			aspect-ratio: 1/1;
			border-radius: 8px;
			background-color: #f5f5f5;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.li-box-select {
			background-color: #4284DC;
		}
		.title {
			color: #333333;
			font-size: 32px;
			font-weight: bold;
		}
		.content {
			color: #999999;
			font-size: 22px;
		}
		.mb-search-btn {
			padding: 0.5rem 0.9375rem;
			background: #4284DC;
			border-radius: 8px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
	}
</style>